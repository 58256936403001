.ant-btn-primary{
    &.green {
        background-color: #67C23A;
        border-color: #67C23A;
        &:hover,
        &:focus,
        &:active,
        &.active{
            background-color: #85ce61 !important;
            border-color: #85ce61 !important;
        }
    }
}