.Emission {
    display: grid;
    grid-template-columns: 250px calc(100vw - 4rem - 250px - 2rem);
    padding: 2rem;
    gap: 2rem;
    height: calc(100vh - 136px);
    &__Left {
        position: sticky;
        display: flex;
        & > .ant-steps-vertical {
            flex: 1;
        }
        top: 1rem;
    }
    &__Main {
        display: flex;
        flex-direction: column;
        max-width: 100%;
    }

    .ant-steps-item-icon {
        .ant-steps-icon {
            .anticon {
                margin-right: 0
            }
        }
    }
}