body {
  background: #f3f7fd;
}
.ant-spin-nested-loading {
  .isDesktop & {
    min-height: calc(75vh - 136px - 48px);
  }
  .isMobile & {
    min-height: 50vh;
  }
}

.ant-statistic-content-suffix {
  opacity: 0.45;
}

.AppFrame {
  .Header {
    padding: 12px 24px 0px;
    &-dark {
      background: #25303f !important;
    }
    ul.ant-menu {
      &.ChildrenMenu {
        padding-bottom: 8px;
      }
      &.RootMenu {
        margin-bottom: 16px;
        gap: 4px;
        // .isDesktop & {
        // }
        .ant-menu-item {
          border-radius: 32px;
          &-selected {
            background-color: rgb(46, 57, 71);
          }
        }
        .ant-menu-submenu-title {
          color: #fff;
        }
      }
      &-dark {
        background: #25303f !important;
        .ant-menu-item-selected {
          background-color: inherit;
        }
        sup {
          right: -12px;
        }
        .ant-badge {
          // background-color: #fff;
          color: #FFF;
        }
      }
      .UserMenu {
        float: right;
        position: absolute;
        right: 1em;
        top: 1em;
        align-self: flex-end;
      }
    }
    svg.logo {
      padding: 8px;
      margin-right: 24px;
    }
    hr {
      border: 0;
      height: 1px;
      background-color: rgb(46, 57, 71);
    }
  }

  .Wrapper {
    margin: 24px;
  }
}

.AppFrame.isMobile {
  .MobileInfo {
    min-height: 46px;
    color: #fff;
  }
  ul.ant-menu {
    &.RootMenu {
      display: flex !important;
      margin-top: 8px;
      .ant-menu-item {
        flex-basis: 100%;
        text-align: center;
        &:first-child {
          text-align: left;
        }
        &:first-child {
          text-align: right;
        }
      }
    }
  }
}
