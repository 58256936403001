.GetOrders {
    .Wrapper {
        height: 100%;
        margin: 0 !important;
        .ant-row {
            max-width: 95vw;
        }
        .ant-col:first-child {
            background: #FFF;
            height: calc(100vh - 136px);
            margin-top: 0px;
            padding-top: 24px;
            padding-left: 24px;
            overflow-y: auto;
            position: absolute;
            left: 0;
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
            width: 50vw;
        }
        .ant-col:last-child {
            padding: 24px;
            margin-left: 24px;

            height: calc(100vh - 136px);
            position: absolute;
            width: 50vw;
            right: 0;

            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
    }
}
.Orders {
    &__POS {
        margin-left: 16px;
        margin-right: 16px;
        .ant-descriptions-item-content {
            background-color: #FFFFFF;
            // .ant-input-number {
            //     position: relative;
            // }
            &.Orders__POS__Number {
                padding: 0 !important;
                // display: flex;
                // align-items: center;
                // justify-content: center;
                height: auto;
                width: auto;
                & > span {
                    position: relative;
                }

                .ant-input-number {
                    // flex: 1 100%;
                    // width: 100%;
                    // height: 100%;
                    width: 100%;
                    height: fit-content;
                    
                    &-input-wrap {
                        // margin-top: 12px;
                    }
                }
            }
        }
        .ant-descriptions-view {
            table {
                tbody {
                    tr:not(:first-child) {
                        .ant-descriptions-item-label {
                            display: none;
                        }
                    }
                }
            }
        }
        
    }
}
