.Dashboard {
    &--WelcomeImage{
        max-width: 75%;
        .isDesktop &{
            max-height: 240px;
        }
    }
    &__Heading{
        font-variant: small-caps;
        text-transform: lowercase;
        user-select: none;
    }
}

.isMobile {
    .ant-col:last-child{
        margin-bottom: 32px;
    }
}