@import '~antd/dist/antd.css';

@import './Views/Login/index.scss';
@import './Assets/global.scss';

.anticon {
  margin-right: 8px;
}

body>iframe[style*='2147483647'] {
  display: none;
}