.Masters{
    .ant-result-subtitle{
        .isDesktop & {
            padding: 0px 25vw;
        }
    }
    .ant-result-extra{
        button{
            margin-bottom: 16px;
        }
    }

}

.MasterSubMenu{
    padding-left: 22px;
    .isMobile &{
        width: 100vw;
        overflow-y: hidden;
        overflow-x: scroll;
    }
    .MasterSubMenuExtras{
        // float: right;
        margin-right: 32px;
        margin-top: 8px;
        background: #4caf50 !important;
        border-color: #8bc34a !important; 
        position: absolute;
        right: 0em;
    }
}

.Sub-create{
    .ant-tabs-tab{
        font-size: 15px;
        font-weight: bold;
        user-select: none;
    }
    .ant-tabs-nav{
        margin-bottom: 0px;
    }
    .ant-tabs-content-holder{
        padding: 16px;
        background: #FFF;
        min-height: 180px;
    }
}

.FormItemInLine{
    .ant-form-item-control{
        display: flex;
        flex-direction: column;
        /*justify-content: flex-start;
        align-items: stretch;
        align-content: stretch;*/
        /* & > div {
            flex-basis: 100%;
        } */
    }
    .Listado{
        min-width: 300px;
    }
}

.hideLabel .ant-form-item-label{
    opacity: 0;
}

.TableListAll{
    .ant-table-cell{
        line-height: 14px;
    }
}

.isDesktop .MastersProducts{
    .Wrapper > .ant-space.ant-space-vertical {
        width: 100%;
        form.ant-form.ant-form-inline{
            display: flex;
            justify-content: space-between;
            flex-direction: row;
            flex-wrap:  nowrap;
            /* align-items: stretch;
            align-content: stretch; */
            & > div{
                flex: 0 0 auto;
                align-self: stretch;
                flex-grow: 10;
            }
            .CheckedClass{
                flex-grow: 1;
            }
        }
    }
}