.WarehouseInjector .Wrapper .ant-row .ant-col {
    // &:first-child {
    //     min-width: 290px !important;
    // }
    // &:last-child {
    //     max-width: min((100vw / 24 * 18) - 290px, 100%) !important;
    // }
}

.WarehouseInjector {
    .ant-form {
        &-item {
            &-row {
                display: flex;
                justify-content: space-between;
            }
            &-control {
                align-items: flex-end;
            }
            &-label {
                label {
                    display: flex;
                    flex-direction: row-reverse;
                    &::after {
                        content: '';
                    }
                }
            }
        }
        .ant-space {
            &-item {
                &:last-child {
                    width: 100%;
                    margin-bottom: 16px;
                }
            }
        }

        // .WarehouseInjector--Submit + .ant-space-horizontal {
        //     margin-bottom: 150px;
        // }
    }
    .ant-page-header-heading {
        min-height: 64px;
        &-left:has(.ant-page-header-heading-sub-title) {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            span {
                text-align: left;
            }
        }
    }
    
    .ant-list-item {
        transition: 300ms ease-in;

        &.disabled {
            opacity: 0.33 !important;
            // grayscale
            filter: grayscale(1);
            cursor: not-allowed;
            background: none !important;
        }
    
        &:hover:not(.disabled),
        &.hovered:not(.disabled) {
            background: #FFF;
            cursor: pointer;
            border-radius: 8px 0px 0px 8px;
            opacity: 1;
            filter: grayscale(0);
        }
    
        &.wohovered {
            &:not(.disabled) {
                opacity: 0.55;
                filter: grayscale(1);
            }
        }
    
        padding: 12px 24px;

        &-meta-avatar {
            img {
                max-width: 48px;
                border-radius: 8px;
            }
        }
    }

    *:not(button) {
        user-select: none;
    }

    &--ReportFrame {
        background: #FFF;
        padding: 24px;
        border-radius: 0px 8px 8px 0px;
        display: flex;
        // height: 800px;

        & > .ant-row {
            width: 100%;
        }

        .ant-empty {
            align-self: center;
            width: 100%;
        }

        .ant-form {
            width: 100%;
        }
    }

    .Wrapper {
        margin-top: 0px !important;
    }

    &--DownloadBtn {
        float: right;
    }

    .newBadge {
        margin-top: -16px;
        // position: absolute;
        // margin-right: -24px;
        // z-index: 100000;
    }

    &--Row {
        &--Step {
            & > .ant-row {
                height: 100%;
            }
            height: 100%;
            margin-top: 16px;
            .ant-upload {
                padding: 16px;
                height: auto;
            }
        }
    }
    
    &--Submit {
        position: absolute;
        bottom: 64px;
        right: 0px;
    }
}

.isMobile {
    form {
        .ant-col-16 {
            margin-bottom: 4px !important;
        }
    }
}

.InjDocuments {
    &__Form {
        .ant-form-item-explain-error {
            text-align: right !important;
        }
    }
    //     display: grid;
    //     // flex-direction: column;
    //     // flex-wrap: wrap;
    //     // align-items: flex-start;
    //     // justify-content: flex-start;
    //     grid-template-columns: 1fr 1fr;
    //     label {
    //         flex-direction: row !important;
    //     }
    // }
}